<template>
	<div class="modal">
		<ion-header>
			<ion-toolbar>
				<ion-title>Mitarbeitende einladen</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="dismissModal(false)">
						<ion-icon
							slot="icon-only"
							color="black"
							:icon="closeOutline"
						></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content :fullscreen="true">
			<ion-list lines="full">
				<ion-item>
					<ion-input
						placeholder="Vorname"
						v-model="first_name"
						required
					></ion-input>
				</ion-item>
				<ion-item>
					<ion-input
						placeholder="Nachname"
						v-model="last_name"
						required
					></ion-input>
				</ion-item>
				<ion-item>
					<ion-input
						placeholder="Email"
						type="email"
						v-model="email"
						required
					></ion-input>
				</ion-item>
			</ion-list>
			<ion-button
				expand="block"
				color="black"
				class="ion-margin"
				@click="inviteUser()"
				:disabled="!filled"
				>Einladen</ion-button
			>
		</ion-content>
	</div>
</template>

<script>
import {
	IonContent,
	IonHeader,
	IonTitle,
	IonList,
	IonItem,
	IonToolbar,
	IonButtons,
	IonButton,
	IonInput,
	IonIcon,
	modalController,
} from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';

export default {
	name: 'MaModal',
	data() {
		return {
			email: '',
			first_name: '',
			last_name: '',
		};
	},
	components: {
		IonContent,
		IonHeader,
		IonTitle,
		IonList,
		IonItem,
		IonInput,
		IonToolbar,
		IonButtons,
		IonButton,
		IonIcon,
	},
	computed: {
		filled() {
			return (
				this.email !== '' && this.first_name !== '' && this.last_name !== ''
			);
		},
	},
	methods: {
		dismissModal(success) {
			modalController.dismiss({
				success,
				noteInfo: success ? this.noteInfo : null,
			});
		},
		async inviteUser() {
			if (
				this.email !== '' &&
				this.first_name !== '' &&
				this.last_name !== ''
			) {
				this.$store.dispatch('updateLoading', true);
				await this.$http.post(`/ma/invite`, {
					first_name: this.first_name,
					last_name: this.last_name,
					email: this.email,
				});
				this.$store.dispatch('updateLoading', false);
				this.dismissModal(true);
			}
		},
	},
	setup() {
		return { closeOutline };
	},
};
</script>

<style lang="scss" scoped>
.ion-page {
	background: white;
}
</style>
