<template>
	<ion-page id="mitarbeiter">
		<PageHeader
			:title="'Mitarbeitende'"
			:lizenzen="$store.state.user.lizenzen"
			:existing="user.length || 0"
		></PageHeader>

		<ion-content :fullscreen="true">
			<ion-list>
				<ion-item
					:detail="true"
					:router-link="`/mitarbeiter/${ma.id}`"
					v-for="ma in user"
					:key="ma.id"
				>
					<ion-avatar slot="start">
						<img :src="imgLink(ma.avatar)" />
					</ion-avatar>
					<ion-label>
						<h2>{{ ma.first_name }} {{ ma.last_name }}</h2>
					</ion-label>
					<ion-chip
						slot="end"
						outline
						:color="ma.status === 'invited' ? 'secondary' : 'primary'"
					>
						<ion-label v-if="ma.status === 'invited'">eingeladen</ion-label>
						<ion-label v-else-if="ma.status === 'active'">aktiv</ion-label>
					</ion-chip>
				</ion-item>
			</ion-list>
			<ion-button
				expand="block"
				color="black"
				class="ion-margin-top ion-padding"
				@click="openModal"
				:disabled="user.length >= $store.state.user.lizenzen"
			>
				Mitarbeitende einladen
				<ion-icon slot="end" :icon="personAddOutline"></ion-icon>
			</ion-button>
			<ion-button
				expand="block"
				color="black"
				fill="outline"
				class="ion-padding"
				@click="moreUser"
			>
				Ich brauche mehr Zugänge
			</ion-button>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonButton,
	IonContent,
	IonPage,
	IonItem,
	IonIcon,
	IonList,
	IonAvatar,
	IonChip,
	IonLabel,
	modalController,
} from '@ionic/vue';

import { personAddOutline } from 'ionicons/icons';

import MaModal from '@/components/MaModal';
import PageHeader from '@/components/PageHeader.vue';

export default {
	name: 'Mitarbeiter',
	components: {
		IonButton,
		IonContent,
		IonIcon,
		IonPage,
		IonItem,
		IonList,
		IonAvatar,
		IonLabel,
		IonChip,
		PageHeader,
	},
	computed: {
		location() {
			return window.location;
		},
	},
	data() {
		return {
			user: [],
		};
	},
	methods: {
		async openModal() {
			const modal = await modalController.create({
				component: MaModal,
			});

			await modal.present();

			const modalResponse = await modal.onDidDismiss();
			if (modalResponse.data && modalResponse.data.success) this.fetchUser();
		},
		imgLink(img) {
			return `${
				this.$store.state.imageBase
			}${img}?access_token=${localStorage.getItem('auth_token')}`;
		},
		async fetchUser() {
			const { data } = await this.$http.get(
				'/users?filter[ladner]=' + this.$store.state.user.id
			);
			this.user = data.data;
		},
		async ionViewWillEnter() {
			this.fetchUser();
		},
		moreUser() {
			this.location.href =
				'mailto:app-biohandel@bioverlag.de?subject=Mehr Lizenzen für BioHandel App';
			return;
		},
	},

	setup() {
		return {
			personAddOutline,
		};
	},
};
</script>

<style lang="scss" scoped>
.bg-grey::part(native) {
	background: var(--ion-color-light);
}

ion-item {
	cursor: pointer;
}

ion-avatar {
	background: white;
	overflow: hidden;
	img {
		margin-top: 0.1rem;
	}
}
</style>
